import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { api } from "../utilities/api";
import { goTo } from "../utilities/utils";

const Unsubscribe = ({ location }) => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const [isMobile, setIsMobile] = useState(false);
  const [secret, setSecret] = useState("");
  const [isError, setIsError] = useState(false);
  const [pageOutcome, setPageOutcome] = useState("");

  useEffect(() => {
    let secret = new URLSearchParams(window.location.search).get(
      "id"
    );

    if (secret) {
      let encodedSecret = window.location.search.split('?id=')[1];
      console.log(encodedSecret);
      setSecret(encodedSecret);
    } else {
      goTo('/');
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [isMobile]);

  const onClickUnsubscribe = () => {
    api.unsubscribeEmail(secret).then(res => {
      if (res.data !== "") {
        setIsError(true);
        return;
      }
      setIsError(false);
      setPageOutcome("unsubscribe");
    }).catch(err => {
      console.error(err);
      setIsError(true);
    });
  }

  const onClickStay = () => {
    api.unsubscribeEmail(secret, true).then(res => {
      if (res.data !== "") {
        setIsError(true);
        return;
      }
      setIsError(false);
      setPageOutcome("stay");
    }).catch(err => {
      console.error(err);
      setIsError(true);
    });
  }

  return (
    <PageLayout>
      <Container>
        <Row>
          <Col>
            <div style={{ textAlign: "center", paddingTop: "80px" }}>
              <PaddedImage width={"300px"} src={pageOutcome === "stay" ? "/images/happy_email.png" : "/images/sad_email.png"} alt={"unsubscribe"} />
              {pageOutcome === "" &&
                <>
                  <Title>Are you sure about unsubscribing?</Title>
                  <p style={{ paddingTop: 10 }}>If you unsubscribe now, you might miss out on the latest deals and promotions from us.</p>
                  {isError &&
                    <div style={{ color: "red", paddingBottom: 20 }}>
                      "Sorry, something went wrong.  If the error persists please contact us."
                    </div>
                  }
                  <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: isMobile ? 260 : 600, margin: 'auto' }}>
                    <OurButton color={'#0494CA'} width={'260px'} mobile={false} onClick={onClickStay}>I'd rather stay</OurButton>
                    <OurButton color={'#E93F25'} width={'260px'} mobile={isMobile} onClick={onClickUnsubscribe}>Unsubscribe Me</OurButton>
                  </div>
                </>
              }
              {pageOutcome === "stay" &&
                <>
                  <Title>Excellent Choice! </Title>
                  <p style={{ paddingTop: 10 }}>We will continue to keep you posted on the latest deals and promotions.</p>
                </>
              }
              {pageOutcome === "unsubscribe" &&
                <>
                  <Title>We are sad to see you go.</Title>
                  <p style={{ paddingTop: 10 }}>You are now unsubscribed.</p>
                </>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default Unsubscribe;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaddedImage = styled(Image)`
    margin-top:-30px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    color: #0494ca;
`;

const OurButton = styled.div<{ color: string, width: string, mobile: boolean }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${props => props.color === '' ? '#fff' : props.color};
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  margin-top: ${props => props.mobile ? '10px' : '0px'};
  text-align: center;
  color: #fff;
  background-color: ${props => props.color ? props.color : 'rgba(0,0,0,0)'};
  width: ${props => props.width};
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  font-weight: 600;
  cursor: pointer;
`